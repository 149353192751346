import { createApp, ref } from 'vue';
import router from '@/router/home';
import Home from '@/apps/Home';
import env from '@/modules/env';

import '@ca-crowdfunding/makuake-ui-n/dist/makuake-ui-n.css';

const app = createApp(Home);
app.use(router);

const initializeGA = measurementId => {
  // gtag.js の読み込み
  const script1 = document.createElement('script');
  script1.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
  script1.async = true;
  document.body.appendChild(script1);

  // グローバルな window.gtag 関数の定義
  const script2 = document.createElement('script');
  script2.text = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', '${measurementId}');`;
  document.body.appendChild(script2);
};

initializeGA(env.GOOGLE_ANALYTICS_ID);

// アプリケーション全体で保持するデータを提供する
// pinia への移行を検討する
app.provide('popularTags', ref([]));
app.provide('tags', ref([]));
app.provide('categories', ref([]));
app.provide('heading', ref(''));

app.mount('#vue-home');
