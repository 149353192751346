<script setup>
import { MSkeleton, MText } from '@ca-crowdfunding/makuake-ui-n';
import ViewBase from '@/components/discover/ViewBase';
import useFetchProjects from '@/composables/common/fetchProjects';

const { fetchEndingSoonProjects, loading, pagination, projects } =
  useFetchProjects();
</script>

<template>
  <ViewBase
    v-model:pagination="pagination"
    class="ending-soon-view"
    :loading
    :projects
    @fetch="fetchEndingSoonProjects({ page: pagination.page })"
  >
    <template #[`project.loader.append`]>
      <MSkeleton class="mt-2" lines="0.5" size="3x-small" sm="x-small" />
    </template>
    <template #[`project.append`]="{ project }">
      <MText
        v-if="project.time_left_label"
        color="secondary"
        class="mt-2"
        size="3x-small"
        sm="x-small"
        >あと{{ project.time_left_label }}で終了</MText
      >
    </template>
  </ViewBase>
</template>
