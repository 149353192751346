import { toValue } from 'vue';

export default function useReturnPrice() {
  const getMinPriceOfReturns = projectReturns => {
    const returns = toValue(projectReturns);
    // リターンがない場合は0を返す
    if (!returns?.length) {
      return 0;
    }

    const validReturns = returns.filter(
      r =>
        r.can_purchase &&
        (!r.limit_amount || r.limit_amount - r.supported_amount),
    );

    // 有効なリターンがない場合は0を返す
    if (!validReturns.length) {
      return 0;
    }

    // 有効なリターンの中で最安値を返す
    return validReturns.reduce(
      (min, r) => (r.price < min ? r.price : min),
      validReturns[0].price,
    );
  };

  return { getMinPriceOfReturns };
}
