<script setup>
import { MChip, MText, useDisplay } from '@ca-crowdfunding/makuake-ui-n';

defineProps({
  finished: {
    type: Boolean,
    default: false,
  },
  store: {
    type: Boolean,
    default: false,
  },
});

const { xs } = useDisplay();
</script>

<template>
  <MChip
    class="rounded-md"
    :color="store ? 'blue' : 'secondary'"
    :density="xs ? 'compact' : 'comfortable'"
    size="x-small"
    label
    variant="flat"
  >
    <template #label>
      <MText
        :color="store ? 'white' : 'primary'"
        size="3x-small"
        sm="x-small"
        tag="span"
        weight="bold"
      >
        {{ store ? 'STORE' : finished ? '終了' : '実施中' }}
      </MText>
    </template>
  </MChip>
</template>
