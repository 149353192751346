<script setup>
import { computed, inject, onBeforeMount, ref } from 'vue';
import { MDivider, MIcon, MText } from '@ca-crowdfunding/makuake-ui-n';
import NavigationItem from '@/components/navigation/NavigationItem';
import useEnvDefinition from '@/composables/envDefinition';
import useLink from '@/composables/link';
import useLocation from '@/composables/navigation/location';
import { ROUTES } from '@/consts/navigation';
import api from '@/modules/api/v2/categories';

const { env } = useEnvDefinition();
const { resolveLink, BLANK } = useLink();
const { getAreas } = useLocation();

const popularTags = inject('popularTags', ref([]));
const categories = inject('categories', ref([]));
const locations = getAreas();

const menus = computed(() => [
  {
    title: ROUTES.HOME.meta.heading,
    label: ROUTES.HOME.name,
    to: { name: ROUTES.HOME.name },
    lists: [
      {
        title: ROUTES.ALL.meta.heading,
        label: ROUTES.ALL.name,
        to: { name: ROUTES.ALL.name },
      },
      {
        title: ROUTES.NEW.meta.heading,
        label: ROUTES.NEW.name,
        to: { name: ROUTES.NEW.name },
      },
      {
        title: ROUTES.COMING_SOON.meta.heading,
        label: ROUTES.COMING_SOON.name,
        to: { name: ROUTES.COMING_SOON.name },
      },
      {
        title: ROUTES.ENDING_SOON.meta.heading,
        label: ROUTES.ENDING_SOON.name,
        to: { name: ROUTES.ENDING_SOON.name },
      },
      {
        title: ROUTES.RANKING.meta.heading,
        label: ROUTES.RANKING.name,
        to: { name: ROUTES.RANKING.name },
      },
      {
        title: ROUTES.PICKUP.meta.heading,
        label: ROUTES.PICKUP.name,
        to: { name: ROUTES.PICKUP.name },
      },
      {
        title: ROUTES.SELECTED_OWNERS.meta.heading,
        label: ROUTES.SELECTED_OWNERS.name,
        to: { name: ROUTES.SELECTED_OWNERS.name },
      },
      {
        title: ROUTES.GOVERNMENT.meta.heading,
        label: ROUTES.GOVERNMENT.name,
        to: { name: ROUTES.GOVERNMENT.name },
      },
      {
        title: ROUTES.ALL_TIME_RANKING.meta.heading,
        label: ROUTES.ALL_TIME_RANKING.name,
        to: { name: ROUTES.ALL_TIME_RANKING.name },
      },
    ],
  },
  {
    title: '人気のタグ',
    label: ROUTES.TAGS_ALL.name,
    to: { name: ROUTES.TAGS_ALL.name },
    lists: popularTags.value.map(tag => ({
      title: `# ${tag.name}`,
      label: `tag_${tag.id}`,
      to: {
        name: ROUTES.TAG.name,
        params: { tagId: tag.id },
        state: { heading: tag.name },
      },
    })),
  },
  {
    title: 'カテゴリー',
    lists: categories.value.map(category => ({
      title: category.name,
      label: `category_${category.category_code}`,
      to: {
        name: ROUTES.CATEGORY.name,
        params: { categorySlug: category.category_code },
        state: { heading: category.name },
      },
    })),
  },
  {
    title: ROUTES.LOCATIONS_ALL.meta.heading,
    label: ROUTES.LOCATIONS_ALL.name,
    to: { name: ROUTES.LOCATIONS_ALL.name },
    lists: locations.map((location, index) => ({
      title: location.name,
      label: `location_${index}`,
      to: {
        name: ROUTES.LOCATION.name,
        params: { locationIds: location.locations.map(l => l.id).join() },
      },
    })),
  },
  {
    title: 'サービス',
    lists: [
      {
        title: 'Makuake STORE',
        label: 'store',
        to: env.MAKUAKE_STORE_URL,
      },
    ],
  },
]);

const fetchCategories = async () => {
  const { data } = await api.fetchAll();
  categories.value = data?.categories?.length ? data.categories : [];
};

onBeforeMount(fetchCategories);
</script>

<template>
  <nav class="navigation p-6 pr-4">
    <template v-for="(menu, i) in menus" :key="i">
      <NavigationItem
        v-if="menu.title"
        class="mb-1"
        :to="menu.to"
        :tracking-id="`navigation_${menu.label}`"
      >
        <MText max-lines="1" tag="h2" weight="bold">
          {{ menu.title }}
        </MText>
        <MIcon
          v-if="menu.to && menu.to.name !== ROUTES.HOME.name"
          name="right"
          size="18"
        />
      </NavigationItem>
      <ul v-if="menu.lists.length">
        <li v-for="(list, j) in menu.lists" :key="j" class="mb-1 last:mb-0">
          <NavigationItem
            :to="list.to"
            :tracking-id="`navigation_${list.label}`"
          >
            <MText
              :append-icon="
                resolveLink(list.to).target === BLANK && 'externalLink'
              "
              size="small"
              tag="span"
              >{{ list.title }}</MText
            >
          </NavigationItem>
        </li>
      </ul>
      <MDivider v-if="i < menus.length - 1" class="my-4" />
    </template>
  </nav>
</template>

<style scoped>
.navigation {
  overflow-y: auto;
  scrollbar-color: rgba(34 34 34 / 40%) transparent;
}
</style>
