<script setup>
import { fromUnixTime, isPast } from 'date-fns';
import { MChip, MText, useDisplay } from '@ca-crowdfunding/makuake-ui-n';
import ProjectLabel from '@/components/common/ProjectLabel';
import RankLabel from '@/components/common/RankLabel';
import ProjectFilter from '@/components/discover/ProjectFilter';
import ViewBase from '@/components/discover/ViewBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import { NO_SORT_OPTIONS } from '@/consts/discover';

const { fetchMostFundedProjects, filter, loading, pagination, projects } =
  useFetchProjects();

const { xs } = useDisplay();
</script>

<template>
  <ProjectFilter
    v-model:filter="filter"
    :is-in-store="false"
    :is-ongoing="false"
    :loading
    :sort-options="NO_SORT_OPTIONS"
    @fetch="fetchMostFundedProjects"
  />
  <ViewBase
    v-model:pagination="pagination"
    class="all-time-ranking-view"
    :loading
    :projects
    @fetch="fetchMostFundedProjects({ page: pagination.page })"
  >
    <template #[`project.loader.append`]>
      <div class="flex mt-2">
        <MChip
          class="rounded-md w-12"
          color="secondary"
          :density="xs ? 'compact' : 'comfortable'"
          size="x-small"
          label
          variant="flat"
        />
      </div>
    </template>
    <template #[`project.thumbnail.append`]="{ project }">
      <RankLabel v-if="project.rank <= 100" :rank="project.rank" />
    </template>
    <template #[`project.subtext`]="{ project }">
      <MText size="2x-small" sm="small" tag="p" weight="bold">
        <span class="text-secondary">総額</span>
        {{
          project.collected_money?.toLocaleString('ja-JP', {
            currency: 'JPY',
            style: 'currency',
          })
        }}
      </MText>
    </template>
    <template #[`project.append`]="{ project }">
      <div
        v-if="
          project.is_new_store_opening ||
          isPast(fromUnixTime(project.expiration_date))
        "
        class="flex mt-2"
      >
        <ProjectLabel v-if="project.is_new_store_opening" store />
        <ProjectLabel v-else finished />
      </div>
    </template>
  </ViewBase>
</template>
