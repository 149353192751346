import client from '@/modules/api/createClient';
import security from '@/modules/api/php/security';

const fetchTags = () => client.phpApiClient.get(`/me/tags`);

const createTag = tagId => security.securePost(`/me/tags/${tagId}/create`);

const deleteTag = tagId => security.securePost(`/me/tags/${tagId}/delete`);

export default {
  fetchTags,
  createTag,
  deleteTag,
};
